<template>
	<div class="condition-select">
		<label> Date Conditions: </label>
		<vue-select
			label="value"
			@update="updateDateFormat"
			:value="property.format?.toLowerCase()"
			:options="types"
			:reducer="(value) => value.value"
		></vue-select>
	</div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount, ref, watch, PropType } from 'vue'
import { DateProperty, DateFormatTypes } from '@/models/form/additional-information/property-types'
import VueSelect from '@/components/Select/VueSelect.vue'

export default defineComponent({
	name: 'DateAttributes',
	components: {
		VueSelect,
	},
	props: {
		dateProperty: {
			type: Object as PropType<DateProperty>,
			required: true,
		},
	},
	emits: ['update'],
	setup(props, context) {
		const property = ref(props.dateProperty)

		const types: { label: string; value: DateFormatTypes }[] = [
			{ label: 'Any Date', value: 'date' },
			{ label: 'Past', value: 'past_date' },
			{ label: 'Past (including today)', value: 'past_or_present_date' },
			{ label: 'Future (including today)', value: 'future_or_present_date' },
			{ label: 'Future', value: 'future_date' },
		]

		const updateDateFormat = (format: DateFormatTypes) => {
			property.value.format = format
			context.emit('update', { ...property.value })
		}

		onBeforeMount(() => {
			if (!property.value.format) property.value.format = 'date' as DateFormatTypes
		})

		watch(
			() => props.dateProperty,
			(dateProperty) => {
				property.value = dateProperty as DateProperty
			}
		)

		return { property, types, updateDateFormat }
	},
})
</script>

<style lang="scss" scoped>
.condition-select {
	width: 40%;
	padding: 10px;
}
</style>
